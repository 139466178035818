<template>
  <div>
    <b-modal modal-class="modal_basic manage-workspace-modal p-0" id="manage-workspace-modal" hide-footer hide-header>
      <SaveBrandV2></SaveBrandV2>
      <DeleteBrandV2 :brand="deleteBrand"></DeleteBrandV2>
      <ArchiveBrandV2></ArchiveBrandV2>
      <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-lg">
        <div class="flex flex-col justify-between h-full">
          <div :class="{'border-b': getBrand.total_items > getBrand.limit}" class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <div class="flex px-3 py-[0.75rem] justify-between items-center">
              <div class="w-full flex gap-x-2">
                <Button
                    id="create-new"
                    type="button"
                    class="bg-blue-600 hover:bg-blue-700"
                    buttonClass="btn-sm"
                    @click="saveBrandModel()"
                >
                  <template v-slot:label>Create New</template>
                </Button>
                <SearchField
                    id="search-workspace"
                    v-model="getBrand.search"
                    @input="brandSearch"
                    type="text"
                    label="Search workspaces"
                    iconName="lock.svg"
                    customClass="h-8 bg-white with_border"
                >
                  <template v-slot:right_btn>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 17 18" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.95166 8.22033C2.95166 5.32428 5.29938 2.97656 8.19543 2.97656C11.0915 2.97656 13.4392 5.32428 13.4392 8.22033C13.4392 9.6277 12.8848 10.9056 11.9824 11.8475C11.9532 11.8708 11.9249 11.896 11.8979 11.923C11.8708 11.9501 11.8457 11.9783 11.8224 12.0075C10.8805 12.9097 9.6027 13.4641 8.19543 13.4641C5.29938 13.4641 2.95166 11.1164 2.95166 8.22033ZM12.5616 14.0009C11.3475 14.9193 9.83508 15.4641 8.19543 15.4641C4.19481 15.4641 0.95166 12.221 0.95166 8.22033C0.95166 4.21971 4.19481 0.976562 8.19543 0.976562C12.1961 0.976562 15.4392 4.21971 15.4392 8.22033C15.4392 9.86011 14.8943 11.3726 13.9758 12.5867L16.7071 15.3181C17.0977 15.7086 17.0977 16.3418 16.7071 16.7323C16.3166 17.1228 15.6835 17.1228 15.2929 16.7323L12.5616 14.0009Z" fill="#757A8A"/>
                    </svg>
                  </template>
                </SearchField>
              </div>
              <div class="w-full flex justify-center items-center">
                <p class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none">Workspaces</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z" fill="#3C4549"/></svg>
              </div>
              <div class="w-full flex items-center justify-end space-x-2">
                <ArchiveFilter @click="archiveFilter($event)"></ArchiveFilter>
                <button type="button" @click.prevent="$bvModal.hide('manage-workspace-modal')" class="cursor-pointer w-6 h-6"><svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.85176 1.28033C10.1447 0.987437 10.1447 0.512563 9.85176 0.21967C9.55887 -0.0732233 9.08399 -0.0732233 8.7911 0.21967L5.03571 3.97505L1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.97505 5.03571L0.21967 8.7911C-0.0732233 9.08399 -0.0732233 9.55886 0.21967 9.85176C0.512563 10.1447 0.987437 10.1447 1.28033 9.85176L5.03571 6.09637L8.7911 9.85176C9.08399 10.1447 9.55887 10.1447 9.85176 9.85176C10.1447 9.55886 10.1447 9.08399 9.85176 8.7911L6.09638 5.03571L9.85176 1.28033Z" fill="black"/>
                </svg></button>
              </div>
            </div>
            <table class="workspace_table text-left flex-1 h-full">
              <thead class="text-[#757A8A] font-semibold text-[0.875rem] bg-[#F4F6FA] font-poppins">
              <tr>
                <td class="pl-3 py-3">Workspace Name</td>
                <td class="py-3	text-center">Links</td>
                <td class="py-3	text-center">Campaign</td>
                <td class="py-3	text-center">Team Members</td>
                <td class="py-3 text-center">Clicks</td>
                <td class="py-3 text-center">Unique Clicks</td>
                <td class="py-3 text-center">Conv. / Rate</td>
                <td class="py-3 text-center">Created</td>
                <td class="py-3 text-center">Default Workspace</td>
                <td class="py-3 pr-3 text-right">More</td>
              </tr>
              </thead>
              <tbody v-if="getBrandLoaders.retrieve">
              <tr>
                <td colspan="9">
                  <p style="padding: 10px;">
                    <clip-loader
                        :size="'26px'"
                        :color="'#1d4ed8'"
                    ></clip-loader>
                  </p>

                </td>
              </tr>
              </tbody>
              <tbody v-else-if="getComponentBrandList.length == 0">
              <tr>
                <td colspan="9" class="">
                  <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
                      getFilteredMessage('brand', getBrand.search, getBrand.date.label, getBrand.archive)
                    }}</p>
                </td>
              </tr>
              </tbody>
              <tbody v-else class="font-poppins text-[#3C4549] text-[0.875rem]">
              <tr :key="index" v-for="(brand, index) in getComponentBrandList" class="border-b border-[#ECEEF5]">
                <td :class="{'opacity-30': brand.archive}" @click="setDefault(brand)" class="cursor-pointer pl-3 py-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-[0.875rem] h-[0.625rem]" viewBox="0 0 17 12" fill="none">
                    <path d="M15.5455 1L5.54545 11L1 6.45455" :stroke="getWorkspace._id === brand._id ? '#2961D2' : '#D2D5DF'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span :class="{'text-[#2961D2]': getWorkspace._id === brand._id}" class="ml-2">{{ brand.name }}</span>
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  {{ commaSeparatedNumber(brand.total_links) }}
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  {{ brand.total_call_to_actions }}
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  0
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  <div v-if="!brand.fetchStats">{{ commaSeparatedNumber(brand.clicks) }}</div>
                  <skeleton v-else></skeleton>
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  <div v-if="!brand.fetchStats">{{ commaSeparatedNumber(brand.unique_visits) }}</div>
                  <skeleton v-else></skeleton>
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  <div v-if="!brand.fetchStats">{{ commaSeparatedNumber(brand.conversions) }} / {{ brand.ctr }}%</div>
                  <skeleton v-else></skeleton>
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  {{ brand.created_at | relativeCaptilize }}
                </td>
                <td :class="{'opacity-30': brand.archive}" class="py-4 text-center">
                  <SwitchButton @click="setDefault(brand)" :disabled="brand.is_default" :status="!brand.is_default"></SwitchButton>
                </td>
                <td class="py-4 pr-4">
                  <div class="float-right">
                    <b-dropdown
                        right
                        ref="workspace_settings_dropdown"
                        class="dropdown-menu-right hide_dropdown_caret default_style_dropdown"
                        no-caret
                    >
                      <div class="dropdown_header justify-between items-center gap-2.5 flex" slot="button-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <circle cx="10.0002" cy="9.99935" r="1.66667" transform="rotate(-90 10.0002 9.99935)" fill="#3C4549"/>
                          <circle cx="3.33317" cy="9.99935" r="1.66667" transform="rotate(-90 3.33317 9.99935)" fill="#3C4549"/>
                          <circle cx="16.6667" cy="9.99935" r="1.66667" transform="rotate(-90 16.6667 9.99935)" fill="#3C4549"/>
                        </svg>
                      </div>

                      <transition name="fade">
                        <ul class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
                          <div v-if="brand.policy.edit" @click.prevent="handleEditBrand(brand)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 16" fill="none">
                              <path d="M11.2271 1.95798C11.6004 1.58471 12.1067 1.375 12.6346 1.375C13.1625 1.375 13.6687 1.58471 14.042 1.95798C14.4153 2.33126 14.625 2.83753 14.625 3.36543C14.625 3.89332 14.4153 4.3996 14.042 4.77288L5.12819 13.6867L1.375 14.625L2.3133 10.8718L11.2271 1.95798Z" stroke="#3C4549" stroke-width="1.24219" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p>Edit</p>
                          </div>
                          <div class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 16" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.72226 0.888584C6.72226 0.397664 6.32429 -0.000305176 5.83337 -0.000305176C5.34245 -0.000305176 4.94449 0.397664 4.94449 0.888584V15.1108C4.94449 15.6017 5.34245 15.9997 5.83337 15.9997C6.32429 15.9997 6.72226 15.6017 6.72226 15.1108V0.888584ZM11.0558 6.22257C11.0558 5.73165 10.6578 5.33368 10.1669 5.33368C9.67595 5.33368 9.27798 5.73165 9.27798 6.22257V15.1115C9.27798 15.6024 9.67595 16.0003 10.1669 16.0003C10.6578 16.0003 11.0558 15.6024 11.0558 15.1115V6.22257ZM1.50009 8.8888C1.99101 8.8888 2.38898 9.28677 2.38898 9.77769V15.111C2.38898 15.6019 1.99101 15.9999 1.50009 15.9999C1.00918 15.9999 0.611206 15.6019 0.611206 15.111V9.77769C0.611206 9.28677 1.00918 8.8888 1.50009 8.8888ZM15.3888 2.77726C15.3888 2.28634 14.9908 1.88837 14.4999 1.88837C14.009 1.88837 13.611 2.28634 13.611 2.77726V15.1106C13.611 15.6015 14.009 15.9995 14.4999 15.9995C14.9908 15.9995 15.3888 15.6015 15.3888 15.1106V2.77726Z" fill="#3C4549"/>
                            </svg>
                            <p>View Stats</p>
                          </div>
                            <div v-if="!brand.is_default && brand.policy.archive" @click="archiveBrandModal(brand._id, index, brand.user_id, !brand.archive)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                              <svg v-if="!brand.archive" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 18 16" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04247 0.94751C0.742821 0.94751 0.499908 1.19042 0.499908 1.49007V5.10713C0.499908 5.40678 0.742821 5.64969 1.04247 5.64969H1.94669V14.5097C1.94669 14.8093 2.1896 15.0522 2.48925 15.0522H15.5107C15.8103 15.0522 16.0532 14.8093 16.0532 14.5097V5.64969H16.9575C17.2572 5.64969 17.5001 5.40678 17.5001 5.10713V1.49007C17.5001 1.19042 17.2572 0.94751 16.9575 0.94751H1.04247ZM15.5555 4.56457C15.5407 4.56336 15.5258 4.56274 15.5107 4.56274C15.4956 4.56274 15.4806 4.56336 15.4658 4.56457H2.53411C2.51932 4.56336 2.50435 4.56274 2.48925 4.56274C2.47414 4.56274 2.45918 4.56336 2.44438 4.56457H1.58503V2.03263H16.415V4.56457H15.5555ZM3.0318 5.64969H14.9681V13.9671H3.0318V5.64969ZM5.45735 8.00024C5.45735 7.7006 5.70026 7.45768 5.99991 7.45768H11.9999C12.2996 7.45768 12.5425 7.7006 12.5425 8.00024C12.5425 8.29989 12.2996 8.5428 11.9999 8.5428H5.99991C5.70026 8.5428 5.45735 8.29989 5.45735 8.00024ZM5.99991 10.4577C5.70026 10.4577 5.45735 10.7006 5.45735 11.0002C5.45735 11.2999 5.70026 11.5428 5.99991 11.5428H11.9999C12.2996 11.5428 12.5425 11.2999 12.5425 11.0002C12.5425 10.7006 12.2996 10.4577 11.9999 10.4577H5.99991Z" fill="#3C4549"/>
                              </svg>
                              <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-[1.188rem] h-4" viewBox="0 0 22 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.04256 2.44727C2.74291 2.44727 2.5 2.69018 2.5 2.98983V6.60689C2.5 6.90654 2.74291 7.14945 3.04256 7.14945H3.94678V16.0094C3.94678 16.3091 4.18969 16.552 4.48934 16.552H17.5108C17.8104 16.552 18.0533 16.3091 18.0533 16.0094V7.14945H18.9576C19.2573 7.14945 19.5002 6.90654 19.5002 6.60689V2.98983C19.5002 2.69018 19.2573 2.44727 18.9576 2.44727H3.04256ZM17.5556 6.06433C17.5408 6.06312 17.5259 6.0625 17.5108 6.0625C17.4957 6.0625 17.4807 6.06312 17.4659 6.06433H4.5342C4.51941 6.06312 4.50444 6.0625 4.48934 6.0625C4.47423 6.0625 4.45927 6.06312 4.44447 6.06433H3.58512V3.53238H18.4151V6.06433H17.5556ZM5.0319 7.14945H16.9682V15.4669H5.0319V7.14945ZM7.45744 9.5C7.45744 9.20035 7.70035 8.95744 8 8.95744H14C14.2996 8.95744 14.5426 9.20035 14.5426 9.5C14.5426 9.79965 14.2996 10.0426 14 10.0426H8C7.70035 10.0426 7.45744 9.79965 7.45744 9.5ZM8 11.9574C7.70035 11.9574 7.45744 12.2004 7.45744 12.5C7.45744 12.7996 7.70035 13.0426 8 13.0426H14C14.2996 13.0426 14.5426 12.7996 14.5426 12.5C14.5426 12.2004 14.2996 11.9574 14 11.9574H8Z" fill="#3C4549"/>
                                <path d="M20.5 1.5L1.5 18.5" stroke="#3C4549" stroke-width="1.08512" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <p>{{ !brand.archive ? 'Archive' : 'Unarchive' }}</p>
                            </div>
                          <div v-if="!brand.is_default && brand.policy.delete" @click.prevent="$bvModal.show('modal-delete-brand-v2'), deleteBrand = {brand: brand._id,index:index,userId: brand.user_id}" class="cursor-pointer group hover:bg-[#F4F6FA] border-t px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 18" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63083 1.72174C5.82519 1.52739 6.08879 1.4182 6.36365 1.4182H9.63638C9.91124 1.4182 10.1748 1.52739 10.3692 1.72174C10.5636 1.9161 10.6727 2.1797 10.6727 2.45456V3.49093H5.32729V2.45456C5.32729 2.1797 5.43647 1.9161 5.63083 1.72174ZM4.12729 3.49093V2.45456C4.12729 1.86144 4.3629 1.29262 4.7823 0.873216C5.2017 0.453817 5.77053 0.218201 6.36365 0.218201H9.63638C10.2295 0.218201 10.7983 0.453817 11.2177 0.873216C11.6371 1.29262 11.8727 1.86144 11.8727 2.45456V3.49093H13.7273H15.3636C15.695 3.49093 15.9636 3.75956 15.9636 4.09093C15.9636 4.4223 15.695 4.69093 15.3636 4.69093H14.3273V15.5455C14.3273 16.1386 14.0917 16.7074 13.6723 17.1268C13.2529 17.5462 12.684 17.7818 12.0909 17.7818H3.9091C3.31598 17.7818 2.74716 17.5462 2.32776 17.1268C1.90836 16.7074 1.67274 16.1386 1.67274 15.5455V4.69093H0.636377C0.305006 4.69093 0.036377 4.4223 0.036377 4.09093C0.036377 3.75956 0.305006 3.49093 0.636377 3.49093H2.27274H4.12729ZM2.87274 4.69093H4.72729H11.2727H13.1273V15.5455C13.1273 15.8203 13.0181 16.0839 12.8237 16.2783C12.6294 16.4726 12.3658 16.5818 12.0909 16.5818H3.9091C3.63424 16.5818 3.37064 16.4726 3.17628 16.2783C2.98193 16.0839 2.87274 15.8203 2.87274 15.5455V4.69093ZM6.36365 7.58184C6.69502 7.58184 6.96365 7.85047 6.96365 8.18184V13.0909C6.96365 13.4223 6.69502 13.6909 6.36365 13.6909C6.03228 13.6909 5.76365 13.4223 5.76365 13.0909V8.18184C5.76365 7.85047 6.03228 7.58184 6.36365 7.58184ZM10.2364 8.18184C10.2364 7.85047 9.96775 7.58184 9.63638 7.58184C9.30501 7.58184 9.03638 7.85047 9.03638 8.18184V13.0909C9.03638 13.4223 9.30501 13.6909 9.63638 13.6909C9.96775 13.6909 10.2364 13.4223 10.2364 13.0909V8.18184Z" fill="black"/>
                            </svg>
                            <p>Remove</p>
                          </div>
                        </ul>
                      </transition>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="py-[0.78rem]">
            <template v-if="!getBrandLoaders.retrieve">
              <div class="flex px-[1rem] w-full justify-between items-center">
                <div class="w-full flex justify-center items-center">
                  <div class="mr-40" v-if="getBrand.total_items > getBrand.limit">
                    <TablePagination
                      :clickedMethod="paginate"
                      :resultCount="getBrand.total_items"
                      :itemsPerPage="getBrand.limit"
                      :currentPage="getBrand.page"
                      :totalPagesNumber="getBrand.total_pages"
                    ></TablePagination>
                  </div>
                  <div v-if="getBrand.total_items > getBrand.limit">
                    <TableCustomPage :page="getBrand.page" @click="paginate"></TableCustomPage>
                  </div>
                </div>
                <PerPageFilter
                  :limit="getBrand.limit"
                  @click="getBrand.limit = $event; fetchBrandByFilters()"
                ></PerPageFilter>
              </div>
            </template>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { brandTypes, userTypes } from '@/state/modules/mutation-types'

export default {
  data() {
    return {
      statusTest: false,
      previousPage: 1,
      deleteBrand: {}
    };
  },
  props: ['selectedWorkspace'],
  computed: {
    ...mapGetters['getWorkspace' ,'getComponentBrandList', 'getBrand', 'getBrandLoaders']
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    SearchField: () => import('@/ui/ui-kit/v2/SearchField.vue'),
    SwitchButton: () => import('@/ui/ui-kit/v2/SwitchButton.vue'),
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    ArchiveFilter: () => import('@/ui/ui-kit/v2/ArchiveFilter.vue'),
    TableCustomPage: () => import('@/ui/ui-kit/v2/TableCustomPage.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue'),
    SaveBrandV2: () => import('@/views/pages/brands/SaveBrandV2.vue'),
    DeleteBrandV2: () => import('@/views/pages/brands/DeleteBrandV2.vue'),
    ArchiveBrandV2: () => import('@/views/pages/brands/ArchiveBrandV2.vue')
  },
  methods: {
    ...mapActions(['fetchBrandAnalytics', 'archiveBrand', 'fetchBrandAnalytics', 'setDefaultWorkspace']),
    async setDefault(brand) {
      await this.setDefaultWorkspace(brand)
      this.$store.commit(brandTypes.SET_WORKSPACE, brand)
      localStorage.setItem('workspace', JSON.stringify(brand))
      await this.pushToCurrentRoute()
      await this.fetchBrandByFilters()
    },
    handleEditBrand (brand) {
      this.$store.commit(brandTypes.SET_BRAND_ADD_VALUE, brand)
      this.$bvModal.show('modal-create-brand-v2')
    },
    archiveFilter(event) {
      this.$store.commit(brandTypes.SET_BRAND_ARCHIVE_STATUS, event)
      this.fetchBrandByFilters()
    },
    saveBrandModel() {
      this.$store.commit(brandTypes.SET_BRAND_ADD_VALUE, {
        _id: null,
        name: null,
        url: null,
        avatar: null,
        resize_url: null
      })
      this.$bvModal.show('modal-create-brand-v2')
    },
    /**
     * Fetching the record on the basis of page number
     * @param page
     * @returns {Promise<void>}
     */
    async paginate (page) {

      this.getBrand.page = page

      // if page is one then no need to check periodic
      if (page !== 1) {
        this.getBrand.periodic = this.isFetchedPeriodic(this.previousPage, page, this.getBrand.periodic)
      }

      // if isAllListFetched true from other source like dropdown then no need to set again isAllListFetched
      if (!this.getBrand.isAllListFetched) {
        // isAllListFetched will only be true only when all record fetched in periodic way
        this.getBrand.isAllListFetched = this.getBrand.periodic && (page >= this.getBrand.total_pages)
      }

      // if previous page is equal to current page then fetch only analytics
      if (this.previousPage === page) {
        this.fetchBrandAnalytics()
        return
      }

      // setting the previousPage
      this.previousPage = JSON.parse(JSON.stringify(page))

      // if pagination is not in periodic way then get record from api
      if (!this.getBrand.periodic && !this.getBrand.isAllListFetched) {
        this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
        await this.fetchBrandList({ page: page })
        await this.setComponentBrandList(this.getBrandList)
        this.fetchBrandAnalytics()
        // resetting the periodic on page 1 and previousPage
        if (page === 1) {
          this.getBrand.periodic = true
          this.previousPage = 1
        }
        return
      }

      // chunck the local record if exist on the basis of pagination otherwise get from DB
      const list = this.getBrandList.slice((page - 1) * this.getBrand.limit, page * this.getBrand.limit)
      if (list && list.length) {
        await this.setComponentBrandList(list)
        this.fetchBrandAnalytics()
        return
      }

      // if no local record found then get from DB
      await this.fetchBrandList({ page: page })
      await this.setComponentBrandList(this.getBrandList.slice((page - 1) * this.getBrand.limit, page * this.getBrand.limit))
      this.fetchBrandAnalytics()
    },
    brandSearch () {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchBrandByFilters()
      }, 600)
    },
    /**
     * Fetching the campaign on the basis of date, search, filters
     */
    async fetchBrandByFilters () {
      this.getBrand.isListFetched = false
      this.getBrand.periodic = true
      this.getBrand.isAllListFetched = false
      this.previousPage = 1
      this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
      await this.fetchBrandList({ page: 1 })
      await this.setComponentBrandList(this.getBrandList)
      this.fetchBrandAnalytics()
    },
    async archiveBrandModal (brandId, index, userId, status) {
      this.$store.commit(userTypes.SET_MODAL_DATA, {
        brand: brandId,
        user_id: userId,
        status: status,
        index: index
      })
      if (this.getProfile.hide_archive_message) {
        this.archiveBrand()
        return
      }

      if(!status) {
        this.archiveBrand()
        return
      }

      this.$bvModal.show('modal-archive-brand-v2')

    },
  }
}
</script>
<style lang="less">

.workspace_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 14.2rem);
    table-layout: fixed;
  }

  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.manage-workspace-modal {
  padding-left: 0 !important;

  .modal-dialog {
    max-width: 100%;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      background: #F7F7FC;

      .modal-header {

      }

      .modal-body {
        padding: 24px;
        position: relative;
        height: 100%;
      }
    }
  }
}
</style>
